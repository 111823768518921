<template>
  <!-- Start ML Feedback Area -->
  <div 
  id="corporate" class="">
     <section
      class="agency-about-area"
    >
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_feedback_corportate_title }}</h2>
              <div class="bar" />
              <p>If you’re looking to include high quality wellbeing activities at your event, or prioritise self-care and boost morale at your practice/organisation, get in touch with VetYogi to enquire now!</p><p>VetYogi Ltd offers bespoke yoga and meditation services predominantly for veterinary professionals from yoga teachers who understand first-hand the challenges veterinary roles can bring.</p><p>What we offer:</p> <p><ul><li>Yoga classes</li><li>Meditation workshops</li><li>Bespoke practice yoga programmes</li><li>Conference/event speaker sessions</li><li>Blogs and written communications</li><li>Bespoke corporate event wellbeing programmes</li><li>Private group/company retreats</li><li>Q&A sessions</li><li>A range of <a href = "https://www.vetyogi.co.uk/shop/2">VetYogi yoga props</a> and <a href = "https://www.vetyogi.co.uk/shop/3">VY yoga-inspired aromatherapy home fragrance products for competitions/speaker gifts</a></li><li>And more!</li></ul><br /><br /><p>We are available for virtual sessions/events and - as the world starts to open up amidst the COVID-19 pandemic - for in-person classes and events too. However, please be mindful our capacity to offer in-person content will depend on any local restrictions in place at the time, plus location. For our international clients we are happy to discuss online content. </p>
              <!-- <div v-html="about" /> -->
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="someImage"
                alt="image"
              >
            </div>
          </div>
        </div>
      </div>
    </section>

  <!-- Corporate Parters section -->
<section class="agency-about-area">
              
</section>

    <section class="agency-about-area">
      <div class="container">
        <a target="_blank" v-for="(image, i) in images" :key="i" :href="image.src" > <img class="item" :src="image.logourl"></a>

       </div>
      
      <!-- <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow> -->
        
        <div class="ml-feedback-slides">
        <carousel
          :autoplay="true"
          :loop="true"
          :smart-speed="1000"
          :autoplay-speed="true"
          :autoplay-timeout="5000"
          :per-page-custom="[[0, 1],[576, 1],[768, 1],[1024, 1], [1200, 1]]"
        >
          <slide
            v-for="item in feedback"
            :key="item.text"
          >
            <div class="single-ml-feedback-item">
              <div class="client-info">
                <h3>{{ item.client }}</h3>
              </div>
              <p>{{ item.text }}</p>
            </div>
          </slide>
        </carousel>
      </div>



    </section>

  <!-- End ML Feedback Area -->
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import someImage from '@/assets/img/vetyogi/about/corpimage.jpg'
// import VueGallerySlideshow from 'vue-gallery-slideshow';
export default {

    name: 'FeedbackTwo',
    components: { Carousel, Slide,   },
    data: function (){
      return {
        someImage: someImage,
        about: this.$store.state.project_feedback_corporate,
          images: [
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fbvra.png?alt=media&token=5484210d-6490-4b56-9290-33ffce5ef271", src: "https://www.bvra.co.uk/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvds.jpg?alt=media&token=d866367a-ed4e-4894-8f82-36dad873a6f9", src: "https://thevds.co.uk/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fwellvet.png?alt=media&token=a5b95703-c618-4ee7-9bc5-c45f65284eb5", src: "https://www.wellvet.co.uk/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2FLVS%20logo%20-%20no%20dates%20PNG.png?alt=media&token=1e72fa38-f768-416d-b303-46bafeaf45b7", src: "https://london.vetshow.com/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Funnamed.jpg?alt=media&token=3b40fa6a-2435-4db4-b23a-77c63d5844af", src: "https://www.lintbells.com/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvetsnow.png?alt=media&token=05e4e82e-7de1-4ffc-ab3e-0ccb7b0c2fe8", src: "https://www.vets-now.com/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Favs.png?alt=media&token=600ae666-7cf5-409f-806d-1230ba81de72", src: "https://www.avsukireland.co.uk/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2FLYPLYV%20logo_TM.webp?alt=media&token=e022d15c-5328-4268-96ed-5a97bff83409", src: "https://www.loveyourpetloveyourvet.com.au/"},                     
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Funnamed%20(1).jpg?alt=media&token=270b8788-b98e-48e7-ac3d-c7ac3e2539d6", src: "https://www.xlvets.co.uk/"},        
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2FpetsApp.png?alt=media&token=5a11d0ed-3a74-43a0-a3a9-72649dd97506", src: "https://petsapp.com/"},    
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2FBVA-Logo.svg?alt=media&token=809ebe71-fc27-454a-a598-ea0cc5628c6a", src: "https://www.bva.co.uk/"}, 
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvsg.png?alt=media&token=d03aae6b-f28d-4851-b048-d36189cdc604", src: "https://www.vsgd.co/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvetfit.jpg?alt=media&token=d501514e-1a36-4ad7-a83e-5486f8185d99", src: "https://www.getvetfit.co.uk/"},                               
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Funnamed.png?alt=media&token=d9e355ac-f814-4e02-9e07-e0d12009defb", src: "https://vtx-cpd.com/"},                               
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvetlifelogo-1.png?alt=media&token=a0367ba7-d454-4904-a4d1-dfec06e7206c", src: "https://www.vetlife.org.uk/"},                               
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fhoodie.png?alt=media&token=2acb32d7-54fc-4719-bea8-1898528d5dbb", src: "https://www.vetlife.org.uk/"},                               
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Funnamed%20(2).jpg?alt=media&token=266a3c15-41de-4b8b-8681-63ed55608a9e", src: "https://www.bvlgbt.org"},                               
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fstreetvet.png?alt=media&token=ff042eea-931c-41fa-a94f-9366a257e323", src: "https://www.streetvet.co.uk/"},                               
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2FBVAlive.png?alt=media&token=6484b978-da46-44af-9bc7-915eb9044e90", src: "https://bvalive.vetshow.com/"}, 
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvetempowered.png?alt=media&token=edfaf726-6b42-4045-8f40-825e5426062a", src: "https://www.vetempowered.com/"},
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvetdynamics.png?alt=media&token=ddbf62cd-1091-447a-835a-00d5266e185f", src: "https://vetdynamics.co.uk/"}, 
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fcafre.jpg?alt=media&token=dc9a2201-30c3-4eb9-b7d7-25cd97930154", src: "https://www.cafre.ac.uk/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Favra.png?alt=media&token=696cfcdf-ce91-4f28-8dcd-163bf63f567e", src: "https://avra.net.au/"},  
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Frspca.jpg?alt=media&token=38ce7357-762e-4b41-a190-704e52182f64", src: "https://www.rspca.org.uk/local/finsbury-park-animal-hospital"},                           
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvetsforpets.jpg?alt=media&token=a070c270-6991-44df-a9f6-8531d1982210", src: "https://www.vets4pets.com/practices/vets-in-blackpool/vets4pets-cleveleys/"},                           
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fthehousecallacademy.png?alt=media&token=8505f66e-d82e-4d9a-b1f1-19716850eb19", src: "https://www.dreveharrison.com/house-call-vet-academy"},                           
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fveterinarywomen.png?alt=media&token=5127697d-00cf-42b0-8b96-f619541a5419", src: "https://www.veterinarywoman.co.uk/"},                           
           { logourl: "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fjhp.png?alt=media&token=8d0ed2f8-79ba-4e36-bd81-7ee9abb89412", src: "https://jhprecruitment.co.uk/"},                           
   

          ],
        index: null,
        feedback:[
          {text: "Vetfit has had the pleasure of collaborating with VetYogi for over a year now. Not only are her sessions incredibly well delivered, but Chloe is a fantastic individual to work with who puts so much energy into her work. This energy reflects onto her VetYogi tribe. In lockdown 1.0 Chloe dedicated her free time to connecting the veterinary community with yoga, and in participating ourselves, found immense calmness and grounding during a time of immense uncertainty. Chloe brings tranquility wherever she goes, with the perfect hint of challenge to help push yourself", img:  "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Fvetfit.jpg?alt=media&token=d501514e-1a36-4ad7-a83e-5486f8185d99",  client: "Holly Sutton, Chief Operating Officer, Vetfit"},  
          {text: "I had the pleasure of working with Chloe to co-present a webinar on stress management and mindfulness for the veterinary profession.  It was an extremely popular presentation, filled with helpful and informative information.  Chloe was wonderful to work with and helped to make the process a lot easier - as well as fun!  I believe Chloe’s experience and qualifications as a veterinarian and yoga instructor are a wonderful combination to help deal with the stressors of working in the veterinary profession.", img:  "https://lh3.googleusercontent.com/proxy/Vdi90CeHJVswbqlXQ4i6CMfTW2ulaGVe5dSsTl0RBmVMTZmwzUvcwp3fcg_6Krp7BQ47vPl_WWGJ4b6ncq7JdLH8LIm_9IsBzaPy6Gc3jQb-GoUzu5Har4qW_4AREr9L",  client: "Dr. Nadine Hamilton, Founder, Love Your Pet Love Your Vet."},     
          {text: "AVS, as the representative organisation of vet students in the UK and Ireland, continually strives to best support our members. The opportunity to work with VetYogi as an organisation and with Chloe as an individual has offered us the chance to offer this support with a fresh approach through the provision of tailored student Yoga sessions. These sessions have been extremely beneficial for students, with great feedback from attendees and granting students a release during a busy exam period. Chloe herself is extremely professional and knowledgeable, whilst also maintaining an approachability that affords trust and a dependability. We couldn't recommend Chloe highly enough.", img:  "https://lh3.googleusercontent.com/proxy/Vdi90CeHJVswbqlXQ4i6CMfTW2ulaGVe5dSsTl0RBmVMTZmwzUvcwp3fcg_6Krp7BQ47vPl_WWGJ4b6ncq7JdLH8LIm_9IsBzaPy6Gc3jQb-GoUzu5Har4qW_4AREr9L",  client: "Izzie Arthur, AVS President 2020-21"},
          {text: "Chloe has been a valued contributor and supporter of WellVet events. She has not only taught fantastic yoga classes, she has also adapted the routines to include family friendly sessions. What we love most about working with Chloe is she not only teaches yoga, but is passionate about supporting the veterinary community with empathy and collaboration.", img:  "https://lh3.googleusercontent.com/proxy/Vdi90CeHJVswbqlXQ4i6CMfTW2ulaGVe5dSsTl0RBmVMTZmwzUvcwp3fcg_6Krp7BQ47vPl_WWGJ4b6ncq7JdLH8LIm_9IsBzaPy6Gc3jQb-GoUzu5Har4qW_4AREr9L",  client: "Liz Barton, WellVet Founder"},
          {text: "We launched our partnership with VetYogi back in August 2020. We wanted to provide our veterinary community with a 6 week yoga course free to our members to offer an opportunity to explore yoga and help improve their well-being after a busy day at the practice. Working with Chloe has been a true collaborative partnership, she is extremely knowledgeable and helped us deliver and shape, what has been, a extremely successful campaign. We also ran a competition which included prizes from VetYogi, again, delivering high quality products to help our community get started for the course. We are now excited to be planning future collaborations together", img:  "https://lh3.googleusercontent.com/proxy/Vdi90CeHJVswbqlXQ4i6CMfTW2ulaGVe5dSsTl0RBmVMTZmwzUvcwp3fcg_6Krp7BQ47vPl_WWGJ4b6ncq7JdLH8LIm_9IsBzaPy6Gc3jQb-GoUzu5Har4qW_4AREr9L",  client: "Jessica Hyatt, Lintbells Trade Marketing and Event Manager. "},
          {text: "Working with VetYogi is an absolute pleasure. Chloe is not only focused on delivering excellent yoga sessions and presenting insightful wellbeing lectures – she really wants to get to the heart of it all. What is our goal? What do we want to achieve? What would offer our staff members and delegates the most benefit, improving their health and wellbeing? Chloe really cares. The VetYogi fragrance and yoga products are also fantastic. We recently purchased a large amount of items for prizes and gifts and we are extremely impressed with the quality. Thank you so much – we look forward to you ECC Virtual Congress sessions and to working with you again in the near future", img:  "https://lh3.googleusercontent.com/proxy/Vdi90CeHJVswbqlXQ4i6CMfTW2ulaGVe5dSsTl0RBmVMTZmwzUvcwp3fcg_6Krp7BQ47vPl_WWGJ4b6ncq7JdLH8LIm_9IsBzaPy6Gc3jQb-GoUzu5Har4qW_4AREr9L",  client: " Helen Batten, Events Lead, Vets Now"},
          {text: "We worked with Chloe for our virtual (due to COVID!) XLVets Festival based on our Thriving in Practice initiative. Chloe delivered three brilliant bespoke sessions focussed on different areas of our initiative with excellent feedback from our members! Thank you, we look forward to some face to face sessions next time", img:  "https://lh3.googleusercontent.com/proxy/Vdi90CeHJVswbqlXQ4i6CMfTW2ulaGVe5dSsTl0RBmVMTZmwzUvcwp3fcg_6Krp7BQ47vPl_WWGJ4b6ncq7JdLH8LIm_9IsBzaPy6Gc3jQb-GoUzu5Har4qW_4AREr9L",  client: "Kerrie Hedley, Chief Operating Officer, XLVets"},
          {text: "After being thrown into a new normal of remote working, the VDS were keen to ensure the team stayed connected and supported, and colleagues wellbeing remained a top priority. Chloe was recommended wholeheartedly by members of the profession, so we organised a personalised yoga and meditation session – it was so easy and convenient and Chloe was happy to work around us which was so refreshing. We thoroughly enjoyed the class, Chloe was comfortable, calm and incredibly reassuring, I’m surprised we all managed to stay awake at the end! A must for total relaxation!", img:  "https://lh3.googleusercontent.com/proxy/Vdi90CeHJVswbqlXQ4i6CMfTW2ulaGVe5dSsTl0RBmVMTZmwzUvcwp3fcg_6Krp7BQ47vPl_WWGJ4b6ncq7JdLH8LIm_9IsBzaPy6Gc3jQb-GoUzu5Har4qW_4AREr9L",  client: " Kate Evans, Marketing and Communications Executive, The Veterinary Defence Society"},
          {text: "At PetsApp we wanted to do something to help our community destress. Chloe was recommended to us, and has been exactly the breath of fresh air everyone needed. She is incredibly easy to work with, exhibiting flexibility that extends beyond her broad vocabulary of yoga poses. With very little notice Chloe was able to put on family-friendly yoga classes that attendees really benefited from and enjoyed. Thank you, Chloe from the whole of the PetsApp pack.", img:  "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2FpetsApp.png?alt=media&token=5a11d0ed-3a74-43a0-a3a9-72649dd97506",  client: "Thom Jenkins, Co-founder and CEO, PetsApp"},
          {text: "Amidst the hustle and bustle of a busy London Vet Show, we were able to provide an oasis of calm in the BVA Members’ Lounge thanks to some relaxing and refreshing sessions by VetYogi. We received great feedback from members who really enjoyed and appreciated some guided time out to focus on their wellbeing.", img:  "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2FBVA-Logo.svg?alt=media&token=809ebe71-fc27-454a-a598-ea0cc5628c6a",  client: "Katharine Blakemore, Membership Marketing Manager, British Veterinary Association"},    
          {text: "Sometimes I wonder what we would do without Chloe in VSGD sometimes. When our worlds turned upside down in the pandemic this VSGDer was first in line to say 'how can I help?'. Chloe has provided a much-needed break from the career and vet chat with yoga sessions for the community throughout the pandemic. Not only has this helped individuals gift themselves some much-deserved downtime it is also a wonderful showcase of the veterinary passport - Chloe using her diversification journey as a yoga instructor. We love working with her and our community love her help too.", img:  "https://firebasestorage.googleapis.com/v0/b/skypet-9792e.appspot.com/o/corplogos%2Funnamed.png?alt=media&token=a95bac27-affd-4ef4-90bb-f3f23a70c901",  client: "Ebony Escalona, Co-founder of Vets: Stay, Go, Diversify"},  
          {text: "I can't say enough about VetYogi.  The personalized yoga videos were exactly what our project needed.  I am thankful for the stress-free, professional, and positive experience which was working with VetYogi.  The scope of work was executed beautifully and within the agreed upon timeline. Challenges encountered on our cross-ocean project were solved swiftly and barely created a blip.  Chloe’s thoughtfulness and cleverness radiates from her videos.  You cannot help but be happy when watching her do her thing. Needless to say, I am extremely satisfied with the results and confidently recommend VetYogi for any project you may be contemplating.",  client: "Eve Ritchie, Communications Officer, Vetlife"},  
          {text: "Chloe lead a live yoga session for our Facebook audience as part of Mental Health Awareness Week. The session was a virtual class for the Vetlife audience to join in with, which is now a great video resource. I’d recommend Chloe as she was great to work with, and produced an excellent session for the Vetlife community. ",  client: "Karine Eusanio, Founder - Hoodie"},  
          {text: "At the Veterinary Thought Exchange (vtx) we wanted to bring together clinical and non-clinical CPD to best support the vets and nurses in our community. Working with Chloe at our virtual event was the perfect opportunity to do this. Chloe delivered an amazing presentation to help busy professionals break down the barriers to yoga. She speaks with kindness and compassion and is a dynamic and engaging speaker. We all left her session understanding the true benefit of what she has to offer. Most importantly, she is an amazing person and we can't wait to work with her again in the future. We were lucky enough to sample some of Chloe’s amazing candles and wax melts. I can safely say that they are now a staple in the vtx office!",  client: "Veterinary Thought Exchange"},  
          {text: "Chloe from VetYogi has provided some awesome sessions for the BVLGBT+ society at our well-being and pride events. Chloe has always been welcoming to our members and runs a friendly session tailored to beginners and more experienced yoga participants. Our sessions covered some yoga and meditation as well as discussion around well-being.",  client: "James Whitmore, BVLGBT+ Entertainment Rep"},  
          {text: "Chloe from VetYogi kindly hosted a yoga hour for StreetVet. We thoroughly enjoyed it and I personally had the best nights sleep I’ve had in ages following the session! I am excitedly awaiting the virtual yoga retreat",  client: "Jade Statt, Co-founder and Clinical Director, Street Vet"},  
          
        ]
      }
    }
}
</script>
<style scoped>

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: space-evenly;
  margin-bottom: 5rem;
}

.item {
  height: 100px !important;
   width: auto !important;
  margin:5px;
  margin: 1rem;
  align-self: center;
  box-shadow: none !important;
}
.a{
  display: inline !important;
  color: #5DADAB
}

.ul{
  color: #5DADAB
}

</style>