<template>
  <div>
    <!-- Start Page Title -->
    <div class="event-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Events</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->
    <div class="agency-services-area ptb-80">
      <div class="container">
        <div class="row">
          <div
            v-for="item in meditation"
            :key="item.id"
            class="col-lg-4 col-sm-6 col-md-6"
          >
            <div class="agency-services-box">

      

              
              <img
                :src="item.better_featured_image.source_url"
                alt="image"
              >

              <div class="contentModified">
                <h3>
                  
                  <a
                    :href="'/blog-details/' + item.slug"
                    v-html="item.title.rendered"
                  />
                  {{ format_date(item.date) }}
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {getPostbyTag} from '@/services/Posts.js'
export default {
    name: 'Services',
    data() {
      return {
        meditation: null,
        imgError: false,
      }
    },
    

    created() {
      this.id = this.$route.params.id;
      getPostbyTag(this.$store.state.wordpress_event_tag_id).then(data =>this.meditation = data),
      this.dataLoaded =  true

      },
      methods: {
        onImgError(){
          this.imgError=true
        },
      format_date(value){
          if (value) {
            return moment(String(value)).format('MMM YYYY')
           }
       },
       
 },

  

}
</script>
