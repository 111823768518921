<template>
  <div>
    <!-- Start Contact Info Area -->
    <section class="contact-info-area ptb-80">
      <div
        id="contact"
        class="container"
      >
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="contact-info-box">
              <div class="icon">
                <feather type="mail" />
              </div>
              <h3>Email Us</h3>
              <p><a :href="'mailto:' + this.$store.state.project_email">{{ this.$store.state.project_email }}</a></p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div class="contact-info-box">
              <div class="icon">
                <feather type="phone" />
              </div>
              <h3>Call Us</h3>
              <p><a :href="'tel:' + this.$store.state.project_phonenumber">{{ this.$store.state.project_phonenumber }}</a></p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div class="contact-info-box">
              <div class="icon">
                <feather type="activity" />
              </div>
              <h3>Social Media</h3>
              <div class="single-footer-widget">
                <ul class="social-links">
                  <li
                    v-for="social in $store.state.project_social_media"
                    :key="social.id"
                  >
                    <a
                      :href="social.to"
                      :class="social.class"
                    ><feather :type="social.icon" /></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Contact Info Area -->

    <!-- End Contact Area -->
  </div>
</template>

<script>
export default {
    name: 'Contact',
     mounted() {
            var section=this.$router.currentRoute.hash.replace("#", "");
            if (section)
                this.$nextTick(()=> window.document.getElementById(section).scrollIntoView());
        },
}
</script>

