<template>
  <div>
    <Banner />
    <About />
    <CTA />
    <Services />
   
    <Blog />
    <vue-cookie-accept-decline
      :ref="'myPanel1'"
      :element-id="'myPanel1'"
      :debug="false"
      :position="'bottom-left'"
      :type="'floating'"
      :disable-decline="false"
      :transition-name="'slideFromBottom'"
      :show-postpone-button="false"
      @status="cookieStatus"
      @clicked-accept="cookieClickedAccept"
      @clicked-decline="cookieClickedDecline"
    >
      <!-- Optional -->
      <div slot="postponeContent">
        &times;
      </div>

      <!-- Optional -->
      <div slot="message">
        We use cookies to ensure you get the best experience on our website. <a
          href="https://cookiesandyou.com/"
          target="_blank"
        >Learn More...</a>
      </div>

      <!-- Optional -->
      <div slot="declineContent">
        OPT OUT
      </div>

      <!-- Optional -->
      <div slot="acceptContent">
        GOT IT!
      </div>
    </vue-cookie-accept-decline>
  </div>
</template>

<script>
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import Banner from './home/Banner';
import About from './home/About';
import Services from './home/Services';
import CTA from '../other-pages/common/CTA'

import Blog from './home/Blog';
export default {
    name: 'Home',
    components: {
        Banner,
        About,
        Services,
        Blog,
        CTA
    },
     methods: {
    cookieStatus (status) {
      this.status = status
    },
    cookieClickedAccept () {
      this.status = 'accept'
      this.$gtag.optIn()
    },
    cookieClickedDecline () {
      this.status = 'decline'
      this.$gtag.optOut()
    },
    cookieRemovedCookie () {
      this.status = null
      this.$refs.myPanel1.init()
    },

    removeCookie () {
      this.$refs.myPanel1.removeCookie()
    }
  }
}
</script>
