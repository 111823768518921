<template>
  <!-- Start Footer Area -->
  <footer class="footer-area bg-f7fafd">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <div class="logo">
              <a href="/">
                <img
                 class="no-box-shadow"
                  src="../../assets/img/vetyogi/VetYogiLogo.png"
                  alt="logo"
                >
              </a>
            </div>
            <p>{{ this.$store.getters.project_banner_strapline }}</p>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget pl-5">
            <h3>VetYogi</h3>
            <ul class="list">
              <li>
                <router-link :to="{ name: 'yoga'}">
                  Yoga
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'blog'}">
                  Blog
                </router-link>
              </li>
              <li>
                <a
                  :href="this.$store.getters.project_shoplo_url"
                >Shop</a>
              </li>
              <li>
                <router-link :to="{ name: 'videos'}">
                  Video
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'events'}">
                  Events
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'create'}">
                  Create
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <h3>Support</h3>
            <ul class="list">
              <li><a :href="'mailto:' + this.$store.state.project_email">Contact Us</a></li>
              <li>
                <router-link :to="{ name: 'about'}">
                  About
                </router-link>
              </li>  
            </ul>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-sm-6">
          <div class="single-footer-widget">
            <ul class="social-links">
              <li
                v-for="social in $store.state.project_social_media"
                :key="social.id"
              >
                <a
                  :href="social.to"
                  :class="social.class"
                ><feather :type="social.icon" /></a>
              </li>
            </ul>
          </div>
        </div>

        <div class="col-lg-12 col-md-12">
          <div class="copyright-area">
            <p v-html="$store.state.project_copywrite_slug " />
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->

    <back-to-top
      bottom="50px"
      right="50px"
    >
      <div class="go-top">
        <feather type="arrow-up" />
      </div>
    </back-to-top>
  </footer>
  <!-- End Footer Area -->
</template>

<script>
import BackToTop from 'vue-backtotop'
export default {
    name: 'Footer',
    components: { BackToTop }
}
</script>
