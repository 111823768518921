import Vue from 'vue'
import VueRouter from 'vue-router';
import { BootstrapVue } from 'bootstrap-vue';
import VueFeather from 'vue-feather';
import vWow from 'v-wow';
import VueCarousel from 'vue-carousel';
import Toasted from 'vue-toasted';
import App from './App.vue'
import './assets/style/custom.scss';
import { router } from './router';
import store from './store';
import VueApollo from "vue-apollo"
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import SocialSharing from 'vue-social-sharing'
import VueGtag from 'vue-gtag'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import VueMeta from 'vue-meta'
import vueChocolat from 'vue-chocolat'


Vue.config.productionTip = false

Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)
Vue.use(VueGtag, 
  { config: { id: 'UA-166931560-1' },
  includes: [
    { id: 'UA-166878657-1' }
  ],
  appName: 'VetYogi',
  pageTrackerScreenviewEnabled: true
  }, router);

Vue.use(VueApollo)
Vue.use(BootstrapVue);
Vue.use(VueFeather);
Vue.use(VueRouter);
Vue.use(vWow);
Vue.use(VueCarousel);
Vue.use(Toasted)
Vue.use(SocialSharing)
Vue.use(VueMeta)
Vue.component('vue-chocolat', vueChocolat)
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
