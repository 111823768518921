<template>
  <!-- Start About Area -->
  <section class="agency-about-area">
    <div class="container-fluid">
      <div class="row align-items-center min-vh-100">
        <div class="col-lg-6 col-md-12">
          <div class="agency-about-img">
            <img
              src="https://admin.vetyogi.com/wp-content/uploads/2024/10/E5A06AC7-3A5E-46DF-93B0-2EE6E415756C.jpg?w=825"
              alt="image"
            >
          </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
          <div class="agency-about-content">
            <h2>
              About VetYogi
            </h2>
            <div class="bar" />
            <div v-html="about" />
            <a
              href="/about#about"
              class="btn btn-secondary"
            >Find Out More</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End About Area -->
</template>

<script>
export default {
    name: 'About',
    data: function() {
        return {
            about: this.$store.getters.project_about_us_home
        }
    }
}
</script>
