<!-- <template>
  <section class="about-title-area">
    <div class="container-fluid">
      <div class="row align-items-center min-vh-100">
        <div class="col-lg-6 col-md-12">
          <div class="agency-about-img">
            <img
              src="https://vetyogi.files.wordpress.com/2018/06/img_0330.jpg?w=825"
              alt="image"
            >
          </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
          <div class="agency-about-content">
            <h2>
              VetYogi Collective
            </h2>
            <div class="bar" />
            <div v-html="about" />
            <a
              href="/about#about"
              class="btn btn-secondary"
            >Find Out More</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'Collective',
    data: function() {
        return {
            about: this.$store.getters.project_collective_strap
        }
    }
}
</script> -->


<template>
  <div>
    <div class="collective-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>VetYogi Collective</h1>
          </div>
        </div>
      </div>
    </div><div class="container-fluid">
      <div class="row align-items-center min-vh-100">
        <div class="col-lg-6 col-md-12">
          <div class="agency-about-img">
            <img
              src="http://admin.vetyogi.com/wp-content/uploads/2023/04/unnamed.jpg"
              alt="image"
            >
          </div>
        </div>
    
        <div class="col-lg-6 col-md-12">
          <div class="agency-about-content">
            <h2>
              VetYogi Collective
            </h2>
            <div class="bar" />
            <div v-html="about" />
            <a
              href="/about#about"
              class="btn btn-secondary"
            >Find Out More</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>



export default {

    name: 'Collective',
    
  data: function (){
    return {
            about: this.$store.getters.project_collective_strap
        }
     }

}
</script>