<template>
  <div>
    <!-- Start Page Title -->
    <div class="meditation-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Meditatons / Pranayama</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->
    
    <div class="agency-services-area ptb-80">
      <div class="container">
        <div class="section-title">
          <h2>Meditations</h2>
          <div class="bar" />
        </div>
        <div class="row">
          <div
            v-for="item in meditation"
            :key="item.id"
            class="col-lg-4 col-sm-6 col-md-6"
          >
            <div class="agency-services-box">
              <img
                :src="item.better_featured_image.source_url"
                alt="image"
              >

              <div class="contentModified">
                <h3>
                  <a
                    :href="'/blog-details/' + item.slug"
                    v-html="item.title.rendered"
                  />
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="section-title">
          <h2>Pranayama</h2>
          <div class="bar" />
        </div>
        <div class="row">
          <div
            v-for="item in pranayama"
            :key="item.id"
            class="col-lg-4 col-sm-6 col-md-6"
          >
            <div class="agency-services-box">
              <img
                :src="item.better_featured_image.source_url"
                alt="image"
              >

              <div class="contentModified">
                <h3>
                  <a
                    :href="'/blog-details/' + item.slug"
                    v-html="item.title.rendered"
                  />
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getPostbyTag} from '@/services/Posts.js'
export default {
  metaInfo: {
    title: 'VetYogi | Meditation',
    titleTemplate: null
  },
    name: 'Services',
    data() {
      return {
        pranayama: null,
        meditation: null,
        dataLoaded: false
      }
    },

    created() {
      this.id = this.$route.params.id;
      getPostbyTag(this.$store.getters.wordpress_meditation_tag_id).then(data =>this.meditation = data),
      getPostbyTag(this.$store.getters.wordpress_pranayama_tag_id).then(data =>this.pranayama = data),
      this.dataLoaded =  true

      }
}
</script>
