<template>
  <div class="single-blog-post-box">
    <div class="entry-thumbnail">
      <a :href="'/blog-details/' + blog_id"><img
        :src="image_url"
        alt="image"
      ></a>
    </div>

    <div class="entry-post-content">
      <div class="entry-meta" />
      <div style="padding-bottom: 10px;">
        {{ format_date(blog_date) }}
      </div>
      <h3>
        <a
          :href="'/blog-details/' + blog_id"
          v-html="title"
        />
      </h3>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
export default {
    name: 'BlogMini',
    props: {
      title: {type: String},
      image_url: {type:String, default: 'www.google.com'},
      blog_id: {type:String, default:'hello'},
      blog_date: {type:String, default:'23-12-99'}
 },

 methods: {
      format_date(value){
          if (value) {
            return moment(String(value)).format('DD-MM-YYYY')
           }
       },
 }
}
</script>
