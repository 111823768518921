<template>
  <div class="agency-services-area min-vh-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ this.$store.state.project_services_title }}</h2>
        <div class="bar" />
       
      </div>

      <div class="row">
        <div
          v-for="item in items"
          :key="item.text"
          class="col-lg-4 col-sm-6 col-md-6"
        >
          <div class="agency-services-box">
            <a :href="item.to">
              <img
                :src="item.img"
                alt="image"
              ></a>
            <div class="content hidden-sm">
              <h3>
                <a
                  :href="item.to"
                  class="text"
                >{{ item.text }}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Services Area -->
</template>

<script>

import shopMeditation from '@/assets/img/vetyogi/services/logo-meditation.png'
import shopImage from '@/assets/img/vetyogi/services/logo-shop.png'
import shopRecipe from '@/assets/img/vetyogi/services/logo-recipe.png'
import shopVideo from '@/assets/img/vetyogi/services/logo-video.png'
import shopBlog from '@/assets/img/vetyogi/services/logo-blog.png'
import shopCommunity from '@/assets/img/vetyogi/services/logo-community.png'
import shopEvents from '@/assets/img/vetyogi/services/logo-events.jpg'
import shopBusiness from '@/assets/img/vetyogi/services/logo-corporate.png'
import shopSeasonal from '@/assets/img/vetyogi/services/logo-seasonal.png'
export default {
    name: 'Services',
    data: function (){
      return {
        items: [
            {text: "Yoga", img: shopMeditation, buttontext: "read more", to: "/yoga"},
            {text: "Collective", img: shopCommunity, buttontext: "read more", to: "https://collective.vetyogi.com"},
          
            {text: "Shop", img: shopImage, buttontext: "read more", to: "https://www.vetyogi.co.uk/"},
            {text: "Business", img: shopBusiness, buttontext: "read more", to: "/business"},
    
            {text: "Blog", img: shopBlog, buttontext: "read more", to: "/blog"},
            // {text: "Community", img: shopCommunity, buttontext: "read more", to: "/blog-details/namastay-in-home-with-vetyogis-surgery-to-sanctuary"},
            {text: "Recipes", img: shopRecipe, buttontext: "read more", to: "/recipes"},
             {text: "Events", img: shopEvents, buttontext: "read more", to: "/events"},
             {text: "Videos", img: shopVideo, buttontext: "read more", to: "/videos"},
            {text: "Seasonal", img: shopSeasonal, buttontext: "read more", to: "https://www.vetyogi.co.uk/shop/newin/6"},
        ]
      }
    }
}
</script>

<style>
</style>
