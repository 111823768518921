<template>
  <!-- Start Boxes Area -->

  <section class="agency-services-area  ptb-160">
    <div
      id="charity"
      class="container"
    >
      <div class="section-title">
        <h2>Who We Champion</h2>
        <div class="bar" />
      </div>
      <div class="row">
        <div
          v-for="item in this.$store.state.project_charities"
          :key="item.text"
          class="col-lg-4 col-md-6 col-sm-6"
        >
          <div class="single-box">
            <a :href="item.url"><h3>{{ item.title }}</h3>
              <p>{{ item.text }}</p></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Boxes Area -->
</template>

<script>
export default {
    name: 'Charity',

}
</script>
