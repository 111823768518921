<template>
  <div>
    <!-- Start Page Title -->
    <div class="blog-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>{{ this.$store.state.project_blog_title }}</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->
  <Mailchimp/>
  
    <!-- Start Blog Area -->
    <section class="blog-area ptb-80">
      <div class="container">
        <div class="row">
          <div
            id="infinite-list"
            class="col-lg-6 col-md-6 col-sm-6"
            style="margin-bottom: 25px;"
          >
            <div class="single-pricing-table">
              <div class="pricing-header">
                <h3>Blogs for</h3>
              </div>

              <div class="price">
                <span><a :href="'/specificposts/' + this.$store.getters.wordpress_veterinary_tag_id">Veterinary Profressionals</a></span>
              </div>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6 col-sm-6"
            style="margin-bottom: 25px;"
          >
            <div class="single-pricing-table">
              <div class="pricing-header">
                <h3>Blogs for</h3>
              </div>

              <div class="price">
                <span><a :href="'/specificposts/' + this.$store.getters.wordpress_everyone_tag_id">Everyone</a></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            v-for="item in blogs"
            :key="item.id"
            class="col-lg-4 col-md-6 test"
          >
            <div v-if="item.better_featured_image">
              <BlogMini
                :title="item.title.rendered"
                :blog_id="item.slug"
                :blog_date="item.date"
                :image_url="item.better_featured_image.source_url"
              />
            </div>
          </div>
        </div>
        <div
          v-if="morepages"
          class="btn"
          @click="loadMore"
        >
          Load More
        </div>
      </div>
    </section>
    <!-- End Blog Area -->
  </div>
</template>

<script>
import {getPostPagination} from '@/services/Posts.js'
import BlogMini from '@/components/other-pages/blog/blogmini'
import Mailchimp from '@/components/other-pages/common/Mailchimp'
export default {
   metaInfo: {
    title: 'VetYogi | The Blog',
    titleTemplate: null
  },
  name: 'BlogGridTwo',
    components: {
      BlogMini,
      Mailchimp
    },

  data: function (){
      return {
        postResponse: null,
        isLoadingMore: null,
        moreItems: [],
        blogs: [],
        pagination_limit: 18,
        pagination_offset: 0,
        pagination_total: null,
    }},
  computed: {
    morepages: function (){
        if (this.pagination_offset < this.pagination_total) {
          return true
    } else return false
  },
},
  watch: {
    postResponse: function (){
      this.blogs = this.postResponse.data,
      this.pagination_total = this.postResponse.headers['x-wp-total']
    }
  },

   beforeMount() {
    this.getInitialBlogs();
  },
  methods: {
    getInitialBlogs () {
      getPostPagination(this.pagination_limit, this.pagination_offset).then(data => this.postResponse = data)
    },
    loadMore: function () {
      this.pagination_offset = this.pagination_offset + this.pagination_limit
      if(this.morepages) {
          getPostPagination(this.pagination_limit, this.pagination_offset).then(data => this.blogs.push(...data.data))
      }
    },
   },
}
</script>

<style>

.alignnone {
  display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

.aligncenter,.alignleft,.alignright {
    display: block;
    padding: 0;
}

.aligncenter {
    float: none;
    margin: .5em auto 1em;
}

.alignright {
    float: right;
    margin: .5em 0 1em 1em;
}

.alignleft {
    float: left;
    margin: .5em 1em 1em 0;
}

.wp-caption {
    padding: 5px 0;
    text-align: center;
}

.wp-caption img {
    display: inline;
}

.wp-caption p.wp-caption-text {
    margin: 5px 0 0;
    padding: 0;
    text-align: center;
    font-size: 75%;
    font-weight: 100;
    font-style: italic;
    color: #ddd;
}



img.alignnone, img.alignleft, img.alignright, img.aligncenter  {
    transition:transform 0.25s ease;
}

img.alignnone:hover, img.alignleft:hover, img.alignright:hover, img.aligncenter:hover  {
    -webkit-transform:scale(1.5);
    transform:scale(1.5);
}

.wp-caption {} /* img caption */
.gallery {}
.gallery-caption {}

/* styles for img sizes */
img.size-full {}
img.size-large {}
img.size-medium {}
img.size-thumbnail {}



img.aligncenter, div.aligncenter, figure.aligncenter, img.wp-post-image {display:block; margin:1em auto;}
img.alignright, div.alignright, figure.alignright {float:right; margin:1em 0 1em 2em;}
img.alignleft, div.alignleft, figure.alignleft, img.wp-post-image.attachment-thumb {float:left; margin:1em 1em 2em 0;}
figure {max-width: 100%; height: auto; margin:1em 0;}
p img.alignright, p img.alignleft {margin-top:0;}


.gallery figure img {pointer-events: none; max-width:100%; height:auto; margin:0 auto; display:block;}


.gallery figure {  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black;float:left; margin:0 2% 1em 0;}


.gallery.gallery-columns-1 figure {width:100%; margin:0 0 1em 0; float:none;}


.gallery.gallery-columns-3 figure {width:32%;}
.gallery.gallery-columns-3 figure:nth-of-type(3n+3) {margin-right:0;}
.gallery.gallery-columns-3 figure:nth-of-type(3n+4) {clear:left;}


.gallery.gallery-columns-2 figure {width:49%;}
.gallery.gallery-columns-2 figure:nth-of-type(even) {margin-right:0;}
.gallery.gallery-columns-2 figure:nth-of-type(odd) {clear:left;}


.gallery.gallery-columns-4 figure {width:23.25%;}
.gallery.gallery-columns-4 figure:nth-of-type(4n+4) {margin-right:0;}
.gallery.gallery-columns-4 figure:nth-of-type(4n+5) {clear:left;}


.gallery.gallery-columns-5 figure {width:18%;}
.gallery.gallery-columns-5 figure:nth-of-type(5n+5) {margin-right:0;}
.gallery.gallery-columns-5 figure:nth-of-type(5n+6) {clear:left;}


.gallery.gallery-columns-6 figure {width:14.2%;}
.gallery.gallery-columns-6 figure:nth-of-type(6n+6) {margin-right:0;}
.gallery.gallery-columns-6 figure:nth-of-type(6n+7) {clear:left;}


.gallery.gallery-columns-7 figure {width:12%;}
.gallery.gallery-columns-7 figure:nth-of-type(7n+7) {margin-right:0;}
.gallery.gallery-columns-7 figure:nth-of-type(7n+8) {clear:left;}


.gallery.gallery-columns-8 figure {width:10.2%;}
.gallery.gallery-columns-8 figure:nth-of-type(8n+8) {margin-right:0;}
.gallery.gallery-columns-8 figure:nth-of-type(8n+9) {clear:left;}


.gallery.gallery-columns-9 figure {width:8.85%;}
.gallery.gallery-columns-9 figure:nth-of-type(9n+9) {margin-right:0;}
.gallery.gallery-columns-9 figure:nth-of-type(9n+10) {clear:left;}


@media (max-width:767px) {
	img.alignright, div.alignright, figure.alignright, img.alignleft, div.alignleft, figure.alignleft, img.wp-post-image.attachment-thumb {display:block; margin:1em auto; float:none;}
	.gallery.gallery-columns-4 figure, .gallery.gallery-columns-5 figure, .gallery.gallery-columns-6 figure, .gallery.gallery-columns-7 figure, .gallery.gallery-columns-8 figure, .gallery.gallery-columns-9 figure {width:100%; margin:0 0 1em 0; float:none;}
}

  /* img.alignnone {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}

  img.alignnone.size-full{
    display: block;
    margin-left: 0;
    margin-right: 0;
    width: 30vw;
}


img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding-bottom: 50px;
}

img.alignleft{
  float: left;
}

img.attachment-thumbnail.size-thumbnail {
  float: center;
} */
</style>>
