import axios from 'axios'
import store from '@/store/index.js'

axios.defaults.baseURL = store.getters.wordpress_backend_api

export function getPost(id) {
    return axios.get('/posts/' + id + "?_embed").then(response => {
    return response.data
  })
  }

export function getCategories(id){
  return axios.get('categories?include=' + id.join()).then(response => {
  return response.data
})}

export function getTags(id){
  return axios.get('tags?include=' + id.join()).then(response => {
  return response.data
})}

export function getPostbyTag(id) {
    return axios.get('/posts/?tags=' + id + '&per_page=100').then(response => {
    return response.data
  })
  }

export function getPosts() {
      let per_page = 50;
      return axios.get('/posts/?per_page=' + per_page).then(response => {
      return response.data
 })
}

export function getPostPagination(limit, offset) {
      return axios.get('/posts?category_name=blog&per_page=' + limit + '&offset=' + offset) .then(response => {
      return response
  })
  }

export function getTagById(id){
  return axios.get('/tags/' + id).then(response => {
  return response.data
})
}

export function getPostBySlug(slug){
  return axios.get('posts?slug=' + slug).then(response => {
  return response.data
})
}