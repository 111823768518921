<template>
  <div>
    <!-- Start Page Title -->
    <div class="blog-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>{{ pageTitle }}</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->
  <Mailchimp/>
    <!-- Start Blog Area -->
    <section class="blog-area ptb-80">
      <div class="container">
        <div class="row">
          <div
            id="infinite-list"
            class="col-lg-6 col-md-6 col-sm-6"
            style="margin-bottom: 50px;"
          >
            <div class="single-pricing-table">
              <div class="pricing-header">
                <h3>Blogs for</h3>
              </div>

              <div class="price">
                <span><a :href="'/specificposts/' + this.$store.getters.wordpress_veterinary_tag_id">Veterinary Professionals</a></span>
              </div>
            </div>
          </div>

          <div
            class="col-lg-6 col-md-6 col-sm-6"
            style="margin-bottom: 50px;"
          >
            <div class="single-pricing-table">
              <div class="pricing-header">
                <h3>Blogs for</h3>
              </div>

              <div class="price">
                <span><a :href="'/specificposts/' + this.$store.getters.wordpress_everyone_tag_id">Everyone</a></span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            v-for="item in blogs"
            :key="item.id"
            class="col-lg-4 col-md-6 test"
          >
            <div v-if="item.better_featured_image">
              <BlogMini
                :title="item.title.rendered"
                :blog_id="item.slug"
                :blog_date="item.date"
                :image_url="item.better_featured_image.source_url"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End Blog Area -->
  </div>
</template>

<script>
import {getPostbyTag, getTagById} from '@/services/Posts.js'
import BlogMini from '@/components/other-pages/blog/blogmini'
import Mailchimp from '@/components/other-pages/common/Mailchimp'
export default {
  name: 'BlogGridTwo',
    components: {
      BlogMini,
      Mailchimp
    },

  data: function (){
      return {
        blogs: [],
        pageType: this.$route.params.id,
        pageTitle: null,
    }},
   mounted() {
     this.checkPageType();
   },
   beforeMount() {
    this.getInitialBlogs();
  },
  methods: {
    getInitialBlogs () {
      getPostbyTag(this.pageType).then(data =>this.blogs = data)
    },
    checkPageType (){

      if(this.pageType == this.$store.getters.wordpress_veterinary_tag_id){
        this.pageTitle = this.$store.getters.project_specific_blog_title_veterinary
      } else if (this.pageType == this.$store.getters.wordpress_everyone_tag_id) {
        this.pageTitle = this.$store.getters.project_specific_blog_title_everyone
      } else {
        getTagById(this.pageType).then(data => this.pageTitle = data.name)
      }
    }
   },
}
</script>
