<template>
  <section class="cta ptb-80" style="">
<div class="box">
  <div class="content">
    <h1>EXPLORE AND JOIN THE VETYOGI COLLECTIVE</h1>
    <p>AN ONLINE WELLBEING PLATFORM FOR THE WHOLE VET COMMUNITY </p>
     <a :href="this.$store.getters.project_collective_url" target="_blank" class="btn"
      >CHECK IT OUT NOW!</a
    >
  </div>
</div>
  </section>
</template>

<script>
export default {
  name: "CTA",
};
</script>



