<template>
  <div>
    <div class="meditation-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Postures, Meditatons, Pranayama and Videos</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="agency-services-area ptb-80">
      <div class="container">
        <div class="row">
          <div
            v-for="item in items"
            :key="item.text"
            class="col-lg-4 col-sm-6 col-md-6"
          >
            <div class="agency-services-box">
              <img
                :src="item.img"
                alt="image"
              >
              <div class="content">
                <h3>
                  <a
                    :href="item.to"
                    class="text"
                  >{{ item.text }}</a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shopYoga from '@/assets/img/vetyogi/yoga/vetyogi-yoga.png'
import shopVideos from '@/assets/img/vetyogi/yoga/vetyogi-video.png'
import shopMeditations from '@/assets/img/vetyogi/yoga/vetyogi-pranayama.png'

export default {

    name: 'Services',
    metaInfo: {
    title: 'VetYogi | Yoga Poses',
    titleTemplate: null
  },
    
  data: function (){
      return {
        items: [
            {text: "Postures", img: shopYoga, buttontext: "read more", to: "/postures"},
            {text: "Videos", img: shopVideos, buttontext: "read more", to: "/videos"},
            {text: "Meditations Pranayamas", img: shopMeditations, buttontext: "read more", to: "/meditation"},
            
        ]
      }
    }


}
</script>