import VueRouter from 'vue-router';
// Pages

import Home from './components/landing-pages/Home';
import About from './components/landing-pages/About';
import Collective from './components/landing-pages/Collective';
import NotFound from './components/other-pages/not-found/NotFound';
import VideoGrid from './components/other-pages/blog/VideoGrid';
import Blog from './components/landing-pages/blog';
import Gallery from './components/landing-pages/gallery';
import SpecificPosts from './components/other-pages/blog/specificposts';
import BlogDetails from './components/other-pages/blog/BlogDetails';
import Postures from './components/landing-pages/yoga/Postures'
import Meditation from './components/landing-pages/yoga/Meditation'
import Create from './components/landing-pages/create';
import Yoga from './components/landing-pages/yoga';
import Items from './components/landing-pages/create/items';
import Recipes from './components/landing-pages/create/recipes';
import Events from './components/landing-pages/events';
import Business from './components/landing-pages/business';

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior: function(to) {
      if (to.hash) {
        return {
          selector: to.hash
        }
      } else {
      return { x: 0, y: 0 };
      }
    },
    routes: [
      { path: '/', component: Home, name: 'home' },
      { path: '/about', component: About, name: 'about' },
      { path: '/business', component: Business, name: 'business' },
      { path: '/postures', component: Postures, name: 'postures' },
      { path: '/not-found', component: NotFound },
      { path: '/videos', component: VideoGrid, name: 'videos', },
      { path: '/blog', component: Blog, name: "blog" },
      { path: '/blog-details/:id', component: BlogDetails, name: 'blog-details' },
      { path: '/meditation', component: Meditation, name: 'meditation' },
      { path: '/specificposts/:id',  component: SpecificPosts, name: "specificposts" },
      { path: '/create',  component: Create, name: "create" },
      { path: '/yoga',  component: Yoga, name: "yoga" },
      { path: '/items',  component: Items, name: "items" },
      { path: '/gallery',  component: Gallery, name: "gallery" },
      { path: '/recipes',  component: Recipes, name: "recipes" },
      { path: '/events',  component: Events, name: "events" },
      { path: '/index.php/:year/:month/:date/:id',  redirect: { name: 'blog-details' } },
      { path: '/collective', component: Collective, name: 'collective' },
      
    ]
});
