<template>
<div class="">
        <Mailchimp/>
  <section class="coming-soon-area">

    <div class="container-fluid">
      <div class="row align-items-center min-vh-100">
        <div class="col-lg-11 col-md-12">
          <div class="agency-banner-content">
            <img
            class="no-box-shadow"
              src="../../../assets/img/vetyogi/VetYogiLogo.png"
              alt="logo"
            >

            <p>{{ this.$store.getters.project_banner_strapline }}</p>
            <a
              href="/about"
              class="btn btn-secondary btn-tertiary"
            >Personal</a>
              <a
              href="/business"
              class="btn btn-secondary btn-tertiary"
            >Business</a>
          </div>
        </div>
      </div>
    </div>
    <ul class="social-list">
      <li class="list-heading">
        Follow us for updates:
      </li>
      <li
        v-for="social in $store.state.project_social_media"
        :key="social.id"
      >
        <a
          :href="social.to"
          :class="social.class"
        ><feather :type="social.icon" /></a>
      </li>
    </ul>
  </section>
  </div>
</template>

<script>
import Mailchimp from '@/components/other-pages/common/Mailchimp'
export default {
    name: 'Banner',
      components: {
      Mailchimp
    },
}

</script>


<style>
.badge {
  right: 9vw !important;
  top: 15vh !important;
}
</style>