<template>
  <div>
    <div class="create-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Let's Create</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="agency-services-area ptb-80">
      <div class="container">
        <div class="row">
          <div
            v-for="item in items"
            :key="item.text"
            class="col-lg-6 col-sm-6 col-md-6"
          >
            <a :href="item.to">
              <div class="agency-services-box">
                <img
                  :src="item.img"
                  alt="image"
                >
                <div class="content hidden-sm">
                  <h3>
                    <a
                      :href="item.to"
                      class="text"
                    >{{ item.text }}</a>
                  </h3>
                </div>

              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import shopRecipes from '@/assets/img/vetyogi/create/vetyogi-receipes.png'


export default {

    name: 'Services',
    
  data: function (){
      return {
        items: [
            {text: "Recipes", img: shopRecipes, buttontext: "read more", to: "/recipes"},
          
            
        ]
      }
    }


}
</script>