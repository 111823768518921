<template>
  <div>
    <!-- Start Page Title -->
    <div class="meditation-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Postures</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->
    <div v-if="dataLoaded">
      <section class="works-area">
        <div class="container">
          <div
            class="section-title"
            style="padding-top:50px;"
          >
            <h2>Foundation Poses</h2>
            <div class="bar" />
          </div>
        </div>

        <div class="works-slides">
          <carousel
            :autoplay="true"
            :pagination-enabled="false"
            :loop="true"
            :autoplay-speed="true"
            :autoplay-timeout="5000"
            :per-page-custom="[[0, 1],[768, 2],[1200, 3], [1500, 4]]"
          >
            <slide
              v-for="pose in foundation_tags"
              :key="pose.slug"
            >
              <div class="single-works">
                <img
                  :src="pose.better_featured_image.source_url"
                  alt="image"
                >

                <router-link
                  class="icon"
                  :to="{ name: 'blog-details', params: {id: pose.slug}}"
                >
                  <feather type="arrow-right-circle" />
                </router-link>

                <div class="works-content">
                  <h3><a v-html="pose.title.rendered" href="#"></a></h3>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </section>

      <section class="works-area">
        <div class="container">
          <div
            class="section-title"
            style="padding-top:50px;"
          >
            <h2>Animal-Themed Poses</h2>
            <div class="bar" />
          </div>
        </div>

        <div class="works-slides">
          <carousel
            :autoplay="true"
            :pagination-enabled="false"
            :loop="true"
            :autoplay-speed="true"
            :autoplay-timeout="5000"
            :per-page-custom="[[0, 1],[768, 2],[1200, 3], [1500, 4]]"
          >
            <slide
              v-for="pose in animal_tags"
              :key="pose.slug"
            >
              <div class="single-works">
                <img
                  :src="pose.better_featured_image.source_url"
                  alt="image"
                >

                <router-link
                  class="icon"
                  :to="{ name: 'blog-details', params: {id: pose.slug}}"
                >
                  <feather type="arrow-right-circle" />
                </router-link>

                <div class="works-content">
                  <h3><a href="#">{{ pose.title.rendered }}</a></h3>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </section>

      <section class="works-area">
        <div class="container">
          <div class="section-title">
            <h2>Next Level</h2>
            <div class="bar" />
          </div>
        </div>

        <div class="works-slides">
          <carousel
            :autoplay="true"
            :pagination-enabled="false"
            :loop="true"
            :autoplay-speed="true"
            :autoplay-timeout="5000"
            :per-page-custom="[[0, 1],[768, 2],[1200, 3], [1500, 4]]"
          >
            <slide
              v-for="pose in next_level_tags"
              :key="pose.id"
            >
              <div class="single-works">
                <img
                  :src="pose.better_featured_image.source_url"
                  alt="image"
                >

                <router-link
                  class="icon"
                  :to="{ name: 'blog-details', params: {id: pose.slug}}"
                >
                  <feather type="arrow-right-circle" />
                </router-link>

                <div class="works-content">
                  <h3>
                    <router-link :to="{ name: 'blog-details', params: {id: pose.slug}}">
                      {{ pose.title.rendered }}
                    </router-link>
                  </h3>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </section>
    </div>
  </div>
  <!-- End About Area -->
</template>

<script>
import {getPostbyTag} from '@/services/Posts.js'

export default {
  metaInfo: {
    title: 'VetYogi | The Postures',
    titleTemplate: null
  },
    name: 'About',
    data(){
        return {
          foundation_tags: null,
          animal_tags: null,
          next_level_tags: null,
          dataLoaded: false
        }
    },

  created() {
    getPostbyTag(this.$store.state.wordpress_next_level_tag_id).then(data => this.next_level_tags = data),
    getPostbyTag(this.$store.state.wordpress_foundation_tag_id).then(data => this.foundation_tags = data),
    getPostbyTag(this.$store.state.wordpress_animal_tag_id).then(data => this.animal_tags = data),
    this.dataLoaded =  true
  }
}


</script>
