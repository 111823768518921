<template>
  <div>
    <!-- Start Page Title -->
    <div class="gallery-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Gallery</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->
    <div class="agency-services-area ptb-80">
      <div class="container">
        <vue-chocolat>
          <a v-for="image in images" :key="image" :href="image">
            <img :src="image" alt="Suspicious pug" style=" height:15rem !important; margin: 0.5rem !important">
          </a>

        </vue-chocolat>
      </div>
    </div>
  </div>
</template>

<script>

import galleryImage1 from '@/assets/img/vetyogi/gallery/gallery1.jpg'
import galleryImage2 from '@/assets/img/vetyogi/gallery/gallery2.jpg'
import galleryImage3 from '@/assets/img/vetyogi/gallery/gallery3.jpg'
import galleryImage4 from '@/assets/img/vetyogi/gallery/gallery4.jpg'
import galleryImage5 from '@/assets/img/vetyogi/gallery/gallery5.jpg'
import galleryImage6 from '@/assets/img/vetyogi/gallery/gallery6.jpg'
import galleryImage7 from '@/assets/img/vetyogi/gallery/gallery7.jpg'
import galleryImage8 from '@/assets/img/vetyogi/gallery/gallery8.jpg'
import galleryImage9 from '@/assets/img/vetyogi/gallery/gallery9.jpg'
import galleryImage10 from '@/assets/img/vetyogi/gallery/gallery10.jpg'
import galleryImage11 from '@/assets/img/vetyogi/gallery/gallery11.jpg'
import galleryImage12 from '@/assets/img/vetyogi/gallery/gallery12.jpg'
import galleryImage13 from '@/assets/img/vetyogi/gallery/gallery13.jpg'
import galleryImage14 from '@/assets/img/vetyogi/gallery/gallery14.jpg'
import galleryImage15 from '@/assets/img/vetyogi/gallery/gallery15.jpg'
import galleryImage16 from '@/assets/img/vetyogi/gallery/gallery16.jpg'
import galleryImage17 from '@/assets/img/vetyogi/gallery/gallery17.jpg'
import galleryImage18 from '@/assets/img/vetyogi/gallery/gallery18.jpg'
export default {
  name: 'Gallery',
  data() {
    return {

      images: [
        galleryImage1,
        galleryImage2,
        galleryImage3,
        galleryImage4,
        galleryImage5,
        galleryImage6,
        galleryImage7,
        galleryImage8,
        galleryImage9,
        galleryImage10,
        galleryImage11,
        galleryImage12,
        galleryImage13,
        galleryImage14,
        galleryImage15,
        galleryImage16,
        galleryImage17,
        galleryImage18,

      ],
    }
  },


}
</script>
