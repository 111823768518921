<template>
  <div>
    <!-- Start Page Title -->
    <div class="meditation-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Videos</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start Blog Area -->
    <section class="blog-area ptb-80">
      <div class="container">
        <div class="row">
          <div
            v-for="(item, index) in videos"
            :key="item.etag"
            class="col-lg-4 col-md-6"
          >
            <div class="single-blog-post">
              <div
                class="blog-image"
                :href="base_url_youtube + item.id.videoId "
              >
                <a :href="base_url_youtube + item.id.videoId ">
                  <img
                    :src="item.snippet.thumbnails.high.url"
                    name="videoicon"
                    alt="image"
                  ></a>
                <a :href="base_url_youtube + item.id.videoId ">
                  <div class="overlay hidden-sm">
                    <div class="text">
                      {{ parseDuration(resolvedVideoData[index].contentDetails.duration) ||'Unknown' }}
                      <br>
                      <a :href="base_url_youtube + item.id.videoId"> <feather type="monitor" /></a>
                    </div>
                  </div>

                </a>
                <div class="date">
                  <feather type="calendar" />
                  {{ format_date(item.snippet.publishedAt ) }}
                </div>
                <div class="blog-post-content">
                  {{ item.snippet.title }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment'

export default {
    name: 'BlogGrid',
    metaInfo: {
    title: 'VetYogi | The Videos',
    titleTemplate: null
  },
    data(){
        return {
          videos:[],
          base_url_youtube: 'http://www.youtube.com/watch?v=',
          videoId: [],
          resolvedVideoData: [],    
        }
    },
     watch: {
        videos: function (){
           var arrayLength = this.videos.length;
            for (var i = 0; i < arrayLength; i++) {
              this.videoId.push(this.videos[i].id.videoId); 
            }
            this.getVideoDuration()
      }
  },
    created() {
      axios.get(`https://www.googleapis.com/youtube/v3/search?key=AIzaSyCLWSUfqfDidWf_VDpy6O5cGLj93L35paU&channelId=UCRKlvpCZ8mvuJHEqaHadnKA&order=date&part=snippet%20&type=video,id&maxResults=50`)
        .then(response => {
          // JSON responses are automatically parsed.
          this.videos = response.data.items
  })
  .catch(e => {
    this.errors.push(e)
  })
  

},
  methods: {
       format_date(value){
          if (value) {
            return moment(String(value)).format('DD-MM-YYYY')
           }
       },
    parseDuration(e){
    var n = e.replace(/D|H|M/g,":").replace(/P|T|S/g,"").split(":");

    if(1 == n.length)
        2!=n[0].length && (n[0]="0"+n[0]),n[0]="0:"+n[0];
    else 
        for(var r=1, l=n.length-1; l>=r;r++)
            2!=n[r].length && (n[r]="0"+n[r]);

    return n.join(":")
},
       getVideoDuration(){
        axios.get('https://www.googleapis.com/youtube/v3/videos?id=' + this.videoId.join(',') + '&part=contentDetails&key=AIzaSyCLWSUfqfDidWf_VDpy6O5cGLj93L35paU&channelId=UCRKlvpCZ8mvuJHEqaHadnK')
        .then(response => {
          this.resolvedVideoData = response.data.items
  })
  .catch(e => {
    this.errors.push(e)
  })
       }
    }
}
</script>
