<template>
  <!-- Start Navbar Area -->
  <header
    id="header"
    :class="['headroom navbar-style-three', {'is-sticky': isSticky}]"
  >
    <div class="startp-nav">
      <div class="container-fluid">
        <nav class="navbar navbar-expand-md navbar-light">
          <b-navbar-toggle target="navbarSupportedContent" />
    
          <b-collapse
            id="navbarSupportedContent" 
            class="collapse navbar-collapse mean-menu" 
            is-nav 
          >
            <ul class="navbar-nav nav">
              <li class="nav-item">
                <router-link
                  :to="{ name: 'home'}"
                  
                  class="nav-link"
                >
                  Home
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'about'}"
                  class="nav-link"
                >
                  About
                  <feather type="chevron-down" />
                </router-link>
                <ul class="dropdown_menu">
                  <li class="nav-item">
                    <router-link
                      :to="{ name: 'about'}"
                      
                      class="nav-link"
                    >
                      Meet the team
                    </router-link>
                  </li>
                   <li class="nav-item">
                    <router-link
                      to="/about#feedback "
                      class="nav-link"
                    >
                      Testimonials
                    </router-link>
                  </li>
    
                  <li class="nav-item">
                    <router-link
                      to="/about#contact "
                      class="nav-link"
                    >
                      Contact Us
                    </router-link>
                  </li>
                  <li class="nav-item"> 
                    <router-link
                      to="/about#charity"
                      class="nav-link"
                    >
                      Who We Champion
                    </router-link>
                  </li>
                </ul>
              </li>
      <li class="nav-item">
                <router-link
                  :to="{ name: 'business'}"
                  class="nav-link"
                >
                  Business
                </router-link>
      </li>
          <li class="nav-item">
                <router-link
                  :to="{ name: 'gallery'}"
                  class="nav-link"
                >
                  Gallery
                </router-link>
      </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'blog'}"
                  class="nav-link"
                >
                  Blog
                  <feather type="chevron-down" />
                </router-link>
                <ul class="dropdown_menu">
                  <li class="nav-item">
                    <span><a :href="'/specificposts/' + this.$store.getters.wordpress_veterinary_tag_id">For Veterinary Professionals</a></span>
                  </li>
                  <li class="nav-item">
                    <a :href="'/specificposts/' + this.$store.getters.wordpress_everyone_tag_id">For Everyone</a>
                  </li>
                </ul>
              </li>
    
              <li class="nav-item">
                <router-link
                  :to="{ name: 'yoga'} "
                  class="nav-link"
                >
                  Yoga
                  <feather type="chevron-down" />
                </router-link>
                <ul class="dropdown_menu">
                  <li class="nav-item">
                    <router-link
                      :to="{ name: 'postures'}"
                      class="nav-link"
                    >
                      Postures
                    </router-link>
                  </li>
    
                  <li class="nav-item">
                    <router-link
                      :to="{name: 'meditation'}"
                      class="nav-link"
                    >
                      Meditations/Pranayama
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link
                      :to="{ name: 'videos'}"
                      class="nav-link"
                    >
                      Videos
                    </router-link>
                  </li>
              
                </ul>
              </li>
    
              <!-- <li class="nav-item">
                <router-link
                  :to="{ name: 'create'}"
                  class="nav-link"
                >
                  Create
                  <feather type="chevron-down" />
                </router-link>
                <ul class="dropdown_menu">
                  <li class="nav-item">
                    <router-link
                      :to="{ name: 'recipes'}"
                      class="nav-link"
                    >
                      Food
                    </router-link>
                  </li>
                </ul>
              </li> -->
              <li class="nav-item">
                <a
                  :href="this.$store.getters.project_shoplo_url"
                  class="nav-link"
                >Shop <feather type="chevron-down" /></a>
                <ul class="dropdown_menu">
                  <li class="nav-item">
                    <a target="_blank" :href="this.$store.getters.project_shoplo_for_home_url">Fragrances</a>
                  </li>
                  <li class="nav-item">
                    <a target="_blank" :href="this.$store.getters.project_shoplo_for_yoga_url">Yoga</a>
                  </li>

                   <li class="nav-item">
                    <a target="_blank" :href="this.$store.getters.project_shoplo_for_accessories_url">Accessories</a>
                  </li>
                </ul>
              </li>
    
              <li class="nav-item">
                <router-link
                  :to="{ name: 'events'}"
                  class="nav-link"
                >
                  Events <feather type="chevron-down" />
                </router-link>
                <ul class="dropdown_menu">
                  <li class="nav-item">
                    <a target="_blank" :href="this.$store.getters.project_shoplo_for_online_url">Retreats</a>
                  </li>
                  <!-- <li class="nav-item">
                    <a target="_blank" href="https://padlet.com/vetyogi/STSNov22"></a>
                  </li> -->
                  </ul>
              </li>
              <li class="nav-item">
                <router-link
                  :to="{ name: 'collective'}"
                  class="nav-link"
                >
                  Collective <feather type="chevron-down" />
                </router-link>
                <!-- <a
                  :href="this.$store.getters.project_collective_url"
                  class="nav-link"
                > Collective </a>              -->
               <ul class="dropdown_menu">
                  <li class="nav-item">
                    <a target="_blank" :href="this.$store.getters.project_collective_signup_url">Join Now</a>
                  </li>
                   <li class="nav-item"> 
                   <a target="_blank" :href="this.$store.getters.project_collective_login_url">Login</a>
                  </li>  
               </ul> 
              </li>
    
            </ul>
          </b-collapse>
        </nav>
      </div>
    </div>
  </header>
  <!-- End Navbar Area -->
</template>

<script>
export default {
    name: 'HeaderFour',
    data() {
        return {
            isSticky: false,
            lastScrollPosition: 0,
             state: false
        }
    },
    mounted() {
       document.addEventListener('click', this.close)
       const that = this;
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY;
            // eslint-disable-next-line no-console
            if (scrollPos >= 300) {
                that.isSticky = true;
            } else {
                that.isSticky = false;
            }
        })
    },
   beforeDestroy () {
    document.removeEventListener('click',this.close)
  }

}

</script>
