<template>
  <!-- Start ML Feedback Area -->
   <div class="agency-services-area"
    id="feedback"
    >
    <div class="container">
      <div class="section-title">
        <h2>What People are Saying About Us</h2>
        <div class="bar" />
       
      </div>

      <div class="row">
        <div
          v-for="item in items"
          :key="item.text"
          class="col-lg-4 col-sm-6 col-md-6"
        >
          <div class="agency-services-box">
            <a :href="item.to">
              <img
                :src="item.img"
                alt="image"
              ></a>
            <div class="content hidden-sm">
              <h3>
                <a
                  :href="item.to"
                  class="text"
                >{{ item.text }}</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
   </div>
  <!-- End ML Feedback Area -->
</template>

<script>
import shopMeditation from '@/assets/img/vetyogi/services/logo-meditation.png'
import shopImage from '@/assets/img/vetyogi/services/logo-corporate.png'

export default {
    name: 'Services',
    data: function (){
      return {
        items: [
            {text: "Clients", img: shopMeditation, buttontext: "read more", to: "/about#clients"},  
            {text: "Business", img: shopImage, buttontext: "read more", to: "/business"},
        ]
      }
    }
}
</script>