<template>
<div class="">
  <section class="blog-area ptb-80 align-items-center min-vh-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ this.$store.state.project_blog_title }}</h2>
        <div class="bar" />
      </div>

      <div class="row">
        <div
          v-for="item in blogs"
          :key="item.text"
          class="col-lg-4 col-md-6"
        >
          <div class="single-blog-post-item">
            <div class="post-image">
              <a :href="item.to">
                <img
                  :src="item.better_featured_image.source_url"
                  alt="image"
                >
              </a>
            </div>
            <div class="post-content">
              <ul class="post-meta">
                <li>  {{ format_date(item.date ) }}</li>
              </ul>
              <h3>
                <a
                  :href="'blog-details/' + item.slug"
                  v-html="item.title.rendered"
                />
              </h3>
              <a
                :href="'blog-details/' + item.slug"
                class="read-more-btn"
              >Find out More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  </div>
</template>

<script>
import {getPostPagination} from '@/services/Posts.js'
import moment from 'moment'
export default {
    name: 'Blog',
    data: function (){
      return {
      blogs: [        ] }

      
},
created() {
      this.id = this.$route.params.id;
      getPostPagination(3,0).then(data =>this.blogs = data.data),
      this.dataLoaded =  true

      },
 methods: {
       format_date(value){
          if (value) {
            return moment(String(value)).format('DD-MM-YYYY')
           }
       },
    },


}
</script>

