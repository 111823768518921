<template>
  <div>
    <!-- Start Page Title -->
    
    <div class="create-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>Recipes for you</h1>
          </div>
        </div>
      </div>
    </div>
    
    <!-- End Page Title -->
    
    <div v-if="dataLoaded">
      <section class="works-area">
        <div class="container">
          <div
            class="section-title"
            style="padding-top:50px;"
          >
            <h2>{{ this.$store.getters.project_recipes_sweet_title }}</h2>
    
            <div class="bar" />
          </div>
        </div>
    
    
    
        <div class="works-slides">
          <carousel
            :autoplay="true"
            :pagination-enabled="false"
            :loop="true"
            :autoplay-speed="true"
            :autoplay-timeout="5000"
            :per-page-custom="[[0, 1],[768, 2],[1200, 3], [1500, 4]]"
          >
            <slide
              v-for="pose in sweet_tags"
              :key="pose.slug"
            >
              <div class="single-works">
                <img
                  :src="pose.better_featured_image.source_url"
                  alt="image"
                >
                <router-link
                  class="icon"
                  :to="{ name: 'blog-details', params: {id: pose.slug}}"
                >
                  <feather type="arrow-right-circle" />
                </router-link>
                <div class="works-content">
                  <h3><a href="#">{{ pose.title.rendered }}</a></h3>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </section>
      <section class="works-area">
        <div class="container">
          <div class="section-title">
            <h2>{{ this.$store.getters.project_recipes_savory_title }}</h2>
    
            <div class="bar" />
          </div>
        </div>
        <div class="works-slides">
          <carousel
            :autoplay="true"
            :pagination-enabled="false"
            :loop="true"
            :autoplay-speed="true"
            :autoplay-timeout="5000"
            :per-page-custom="[[0, 1],[768, 2],[1200, 3], [1500, 4]]"
          >
            <slide
              v-for="pose in savory_tags"
              :key="pose.id"
            >
              <div class="single-works">
                <img
                  :src="pose.better_featured_image.source_url"
                  alt="image"
                >
                <router-link
                  class="icon"
                  :to="{ name: 'blog-details', params: {id: pose.slug}}"
                >
                  <feather type="arrow-right-circle" />
                </router-link>
                <div class="works-content">
                  <h3>
                    <router-link
                      :to="{ name: 'blog-details', params: {id: pose.slug}}"
                      v-html="pose.title.rendered"
                    />
                  </h3>
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </section>
    </div>
  </div>
    
  <!-- End About Area -->
</template>

<script>
import { getPostbyTag } from '@/services/Posts.js'

export default {
    name: 'About',
    metaInfo: {
    title: 'VetYogi | The Recipes',
    titleTemplate: null
  },
    data() {
        return {
            savory_tags: null,
            sweet_tags: null,
            dataLoaded: false
        }
    },

    created() {
        getPostbyTag(this.$store.getters.wordpress_recipe_savory_tag_id).then(data => this.savory_tags = data),
            getPostbyTag(this.$store.getters.wordpress_recipe_sweet_tag_id).then(data => this.sweet_tags = data)
        this.dataLoaded = true
    },
}
</script>
