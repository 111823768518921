<template>
  <div>
    <!-- Start Page Title -->
    <div class="about-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>{{ this.$store.state.project_corporate_title }}</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start About Area -->


    <!-- End About Area -->
    <section id="about" class="agency-about-area">
      <div class="container-fluid">
        <div class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;">
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <div>
                <p>VetYogi offers bespoke yoga and meditation services predominantly for veterinary professionals from
                  yoga teachers who understand first-hand the challenges veterinary roles can bring, and we love working
                  directly with vet practices/clinics and associated businesses. If you’re looking to include
                  high-quality wellbeing activities at your event/conference, or prioritise self-care and boost morale
                  at your practice/organisation, get in touch with VetYogi to enquire now!</p>
                <p>What we offer:
                <ul>
                  <li>Yoga classes</li>
                  <li>Meditation workshops</li>
                  <li>Bespoke practice yoga programmes</li>
                  <li>Conference/event CPD speaker sessions</li>
                  <li>Blogs and written communications</li>
                  <li>Bespoke corporate event wellbeing programmes</li>
                  <li>Private group/company retreat attendance</li>
                  <li>Online CPD webinars</li>
                  <li>A range of VetYogi yoga props and VY yoga-inspired aromatherapy home fragrance products for
                    staff/competitions/speaker gifts</li>
                  <li>And more - including our online yoga & meditation subscription platform - the VetYogi Collective
                    (see below)</li>
                </ul>
                We are available for in-person and online sessions/events, however, please be mindful our capacity to
                offer in-person content for specific practices will depend on availability and location, though we are
                open to discussing all options!
                </p>
              </div>
              <!-- <h2>Introducing VetYogi Collective</h2>
              <div class="bar" />

              <div>
                <p>VetYogi offers yoga and meditation services for veterinary professionals delivered by yoga teachers who understand first-hand the challenges veterinary roles can bring.
Our Yogi practice subscription membership makes online yoga and meditation accessible for busy practice staff:</p>
<ul>
  <li>Help improve self-care and wellbeing</li>
<li>Boost staff morale</li>
<li>Aid recruitment and retention (you’ll receive our VetYogi Collective membership badge to display to potential employees and showcase your wellbeing values)</li>
</ul>
<p>For more info see: https://collective.vetyogi.com, and email chloe@vetyogi.com to request your bespoke Business package and pricing information booklet. </p></div> -->
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img :src="someImage" alt="image">
            </div>
          </div>
        </div>
        <div class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;">
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img :src="someImage1" alt="image">
            </div>

          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">

              <h2>Introducing VetYogi Collective</h2>
              <div class="bar" />

              <div>
                <p>Launched in 2023, the VetYogi Collective is our online yoga & meditation wellbeing space for the
                  global vet community. With a mix of on-demand and live yoga and meditation classes, the VetYogi
                  Collective creates exclusive content for the whole veterinary team, and offers a variety of yoga and
                  meditation styles and membership benefits to suit each role in our community, from vets & nurses to
                  receptionists, management and students. </p>
                <p>Our Yogi practice subscription membership makes online yoga and meditation accessible and bespoke for
                  vet teams:
                </p>
                <p>
                <ul>
                  <li>Help improve self-care and wellbeing</li>
                  <li>Boost staff morale</li>
                  <li>Aid recruitment and retention (you’ll receive our VetYogi Collective membership badge to display
                    to potential employees and showcase your wellbeing values)</li>
                </ul>
              </p>
                <p>For more info see: <a href=https://collective.vetyogi.com>VetYogi Collective</a>, and email <a href="mailto:chloe@vetyogi.com">chloe@vetyogi.com</a> to request your bespoke Business package and pricing information booklet. It is also our pleasure to offer discounts to charities and non-profit organisations, such as StreetVet and the RSPCA, so please let us know if this is your situation. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FeedbackCorporate />
    <ContactVue />
  </div>
</template>

<script>

import FeedbackCorporate from '@/components/other-pages/common/FeedbackCorporate';
import someImage from '@/assets/img/vetyogi/about/about.jpg'
import someImage1 from '@/assets/img/vetyogi/about/about2.jpg'
import ContactVue from '@/components/other-pages/common/Contact.vue';


export default {
  name: 'About',
  components: {

    ContactVue,
    FeedbackCorporate
  },
  data: function () {
    return {
      someImage: someImage,
      someImage1: someImage1,
      about: this.$store.state.project_about_us_strap
    }
  }
}
</script>
<style scoped>
.p a {
  display: inline !important;
  color: #5DADAB
}

.ul {
  color: #5DADAB
}
</style>