<template>
  <div>
    <!-- Start Page Title -->
    <div class="blog-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1 v-if="blogDetails.title.rendered" v-html="blogDetails.title.rendered" />
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->
  <Mailchimp/>
    <!-- Start Blog Details Area -->
    <section class="blog-details-area ptb-80">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="blog-details-desc">
              <div class="article-image">
                <span v-if="blogDetails.better_featured_image.media_details.sizes.large">
                  <img
                  :src="blogDetails.better_featured_image.media_details.sizes.large.source_url"
                  alt="image"
                >
                </span>
                <span v-else-if="blogDetails.better_featured_image.media_details.sizes.medium">
                  <img
                  :src="blogDetails.better_featured_image.media_details.sizes.medium.source_url"
                  alt="image"
                >
                </span>
              </div>

              <div class="article-content">
                <div class="entry-meta">
                  <ul>
                    <li><feather type="clock" /> {{ format_date(blogDetails.date) }}</li>
                  </ul>
                </div>
                <h3 v-if="blogDetails.title.rendered" v-html="blogDetails.title.rendered" />
                <p v-if="blogDetails.title.rendered" v-html="blogDetails.content.rendered" />
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-12">
            <aside
              id="secondary"
              class="widget-area"
            >
              <section class="widget widget_tag_cloud" v-if="tags.length > 1">
                <h3 class="widget-title">
                  Tags
                </h3>

                <div class="tagcloud">
                  <span
                    v-for="(resolvedTag, index) in resolvedTags"
                    :key="resolvedTag.id"
                  >
                    <a :href="'/specificposts/' + tags[index]">{{ resolvedTag.name }}</a>
                  </span>
                </div>
              </section>

              <section class="widget widget_tag_cloud">
                <h3 class="widget-title">
                  Share
                </h3>

                <div class="tagcloud">
                  <div v-if="blogDetails.title.rendered">
                  <social-sharing
                    :url="this.$route.query.page"
                    :title="blogDetails.title.rendered"
                    description="Vet and Yoga Teacher."
                    quote="Vet and Yoga Teacher."
                    hashtags="vetyogi blogpost"
                    twitter-user="vetyogi"
                    inline-template
                  >
                    <div>
                      <span
                        v-for="social in $store.state.project_social_media"
                        :key="social.id"
                      >
                        <network :network="social.class">
                          <a>{{ social.name }}</a>
                        </network>
                      </span>
                    </div>
                  </social-sharing>
                  </div>
                </div>
              </section>
            </aside>
          </div>
        </div>
      </div>
    </section>
    <!-- End Blog Details Area -->
  </div>
</template>

<script>

import {getTags, getPostBySlug} from '@/services/Posts.js'
import Mailchimp from '@/components/other-pages/common/Mailchimp'
import moment from 'moment'

export default {
    name: 'BlogDetails',
    components: {
      Mailchimp
    },
    metaInfo: {
    title: 'VetYogi | A Special Blog Post',
    titleTemplate: null,
    

  },
    data(){
        return {
          id: null,
          blogDetails: [],
          resolvedTags: null,
          resolvedTitle: null
        }
    },
    computed: {
      tags: function(){
        if(this.blogDetails.tags){
        return this.blogDetails.tags
        } else {
          return null
        }
      }
    },

    watch: {
     tags: {
       handler (){
          getTags(this.tags).then(data => this.resolvedTags = data)
       }
     },
   },
  created() {
        this.slug = this.$route.params.id;
        getPostBySlug(this.slug).then(data => this.blogDetails = data[0])
        this.dataLoaded =  true
        },

    methods: {
         format_date(value){
            if (value) {
              return moment(String(value)).format('DD-MM-YYYY')
             }
         },
      }
}
</script>
