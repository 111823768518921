<template>
  <div>
    <!-- Start Page Title -->
    <div class="about-title-area">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container">
            <h1>{{ this.$store.state.project_about_us_title }}</h1>
          </div>
        </div>
      </div>
    </div>
    <!-- End Page Title -->

    <!-- Start About Area -->
    <section
      id="about"
      class="agency-about-area"
    >
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_about_us_title }}</h2>
              <div class="bar" />
              <div v-html="about" />
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="someImage"
                alt="image"
              >
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="agency-about-area">
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="this.$store.state.project_teammembers_one_name_image"
                alt="image"
              >
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_teammembers_one_name }}</h2>
              <div class="bar" />
              <div v-html="this.$store.state.project_teammembers_one_name_text" />
            </div>
          </div>
        </div>
      </div>
    </section>
    
    <section class="agency-about-area">
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
          
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_teammembers_three_name }}</h2>
              <div class="bar" />
              <div v-html="this.$store.state.project_teammembers_three_name_text" />
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="this.$store.state.project_teammembers_three_name_image"
                alt="image"
              >
            </div>
          </div>
        </div>
      </div>
    </section>



        <section class="agency-about-area">
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="this.$store.state.project_teammembers_five_name_image"
                alt="image"
              >
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_teammembers_five_name }}</h2>
              <div class="bar" />
              <div v-html="this.$store.state.project_teammembers_five_name_text" />
            </div>
          </div>
        </div>
      </div>
    </section>
      
    <section  
      class="agency-about-area"
    >
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_teammembers_six_name }}</h2>
              <div class="bar" />
              <div v-html="this.$store.state.project_teammembers_six_name_text" />
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="this.$store.state.project_teammembers_six_name_image"
                alt="image"
              >
            </div>
          </div>
        </div>
      </div>
    </section>



    <section class="agency-about-area">
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="this.$store.state.project_teammembers_seven_name_image"
                alt="image"
              >
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_teammembers_seven_name }}</h2>
              <div class="bar" />
              <div v-html="this.$store.state.project_teammembers_seven_name_text" />
            </div>
          </div>
        </div>
      </div>
    </section>
    
    
    
    <section  
      class="agency-about-area"
    >
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_teammembers_four_name }}</h2>
              <div class="bar" />
              <div v-html="this.$store.state.project_teammembers_four_name_text" />
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="this.$store.state.project_teammembers_four_name_image"
                alt="image"
              >
            </div>
          </div>
        </div>
      </div>
    </section>




    
    <section  
      class="agency-about-area"
    >
      <div class="container-fluid">
        <div
          class="row align-items-center"
          style="padding-top:50px; padding-left: 50px; padding-right: 50px; padding-bottom: 50px;"
        >
         
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-img">
              <img
                :src="this.$store.state.project_teammembers_two_name_image"
                alt="image"
              >
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <div class="agency-about-content">
              <h2>{{ this.$store.state.project_teammembers_two_name }}</h2>
              <div class="bar" />
              <div v-html="this.$store.state.project_teammembers_two_name_text" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End About Area -->

    <Feedback />
    <FeedbackTwo />
    <!-- <FeedbackCorporate /> -->
    <Charity />
    <ContactVue />
  </div>
</template>

<script>

import FeedbackTwo from '@/components/other-pages/common/FeedbackTwo';
import Feedback from '@/components/other-pages/common/Feedback';
// import FeedbackCorporate from '@/components/other-pages/common/FeedbackCorporate';
import Charity from '@/components/other-pages/common/Charities';
import someImage from '@/assets/img/vetyogi/about/about1.jpg'
import ContactVue from '@/components/other-pages/common/Contact.vue';


export default {
    name: 'About',
    components: {
      Feedback,
        FeedbackTwo,
        Charity,
        ContactVue
    },
    data: function (){
      return {
        someImage: someImage,
        about: this.$store.state.project_about_us_strap
        }
      }
}
</script>
